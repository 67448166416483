<template>
  <div class="container-fluid p-4">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  validations: {},
  created() {},
  mounted: function () {},
  methods: {}
};
</script>
